import React from 'react'
//component.css

const Footer = () => {
  return (
    <div>
      <div className='part2'>
        <div>&copy; 2024 All rights reserved</div>
        <div>support@vsmartengine.com</div>
        <div>Ph : 91-9566191759</div>
        <div>learnhub.vsmartengine.com</div>
      </div>
      </div>
   
  )
}

export default Footer
